<template>
  <div v-if="settings">
    <h1 class="heading">Partner Settings</h1>
    <v-tabs v-model="active_tab">
      <v-tab>
        Contact
      </v-tab>
      <v-tab>Legal</v-tab>
      <v-tab>
        Help
      </v-tab>
      <v-tab>
        Menu
      </v-tab>
      <v-tab>
        Logo
      </v-tab>
      <v-tab>
        Global
      </v-tab>
      <v-tab>
        Letters
      </v-tab>
      <v-tab-item style="min-height: 400px">
        <div style="margin-bottom: 15px;">
          <label  class="label" style="display:block;margin-bottom:15px;font-weight: bold;font-size:20px"></label>
          <Editor
              api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
              :init="options"
              v-model="settings.contact"
          >
          </Editor>
        </div>
      </v-tab-item>
      <v-tab-item style="min-height: 400px">
        <div style="margin-bottom: 15px;">
          <label  class="label" style="display:block;margin-bottom:15px;font-weight: bold;font-size:20px"></label>
          <Editor
              api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
              :init="options"
              v-model="settings.legal"
          >
          </Editor>
        </div>
      </v-tab-item>
      <v-tab-item style="min-height: 400px">
        <div style="margin-bottom: 15px;">
          <label  class="label" style="display:block;margin-bottom:15px;font-weight: bold;font-size:20px"></label>
          <div style="display: flex;justify-content: flex-end">
          <v-btn style="margin-bottom: 15px;" outlined small color="primary" @click="openCreateHelp">Create help item</v-btn>
          </div>
          <v-data-table
              :headers="helpHeaders"
              :items="settings.help"
              class="elevation-1"
              hide-default-footer
          >
            <template v-slot:body>
              <tbody>
              <tr v-for="item in settings.help" :key="item.question">
                <td v-html="item.question">

                </td>
                <td v-html="item.answer">

                </td>
                <td>

                  <v-menu offset-y open-on-hover bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title style="cursor: pointer" @click="openEditHelp(item)">Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="cursor: pointer;color:red;" @click="openDeleteHelp(item)">Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
      <v-tab-item style="min-height: 400px">
        <div style="margin-bottom: 15px;">
          <label  class="label" style="display:block;margin-bottom:15px;font-weight: bold;font-size:20px"></label>
          <div style="display: flex;justify-content: flex-end">
            <v-btn style="margin-bottom: 15px;" outlined small color="primary" @click="openCreateMenu">Create menu item</v-btn>
          </div>
          <v-data-table
              :headers="menuHeaders"
              :items="settings.menu"
              class="elevation-1"
              hide-default-footer
          >
            <template v-slot:body>
              <tbody v-if="settings.menu.length">
              <tr v-for="item in settings.menu" :key="item.question">
                <td>
                  <v-icon>{{item.icon}}</v-icon>
                </td>
                <td>
                  {{item.name}}
                </td>
                <td>
                  <v-btn outlined small @click="openLink(item.link)" color="primary">Open</v-btn>
                </td>
                <td>
                  <v-menu offset-y open-on-hover bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title style="cursor: pointer" @click="openEditModal(item)">Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title style="cursor: pointer;color:red;" @click="openDeleteMenuItem(item)">Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="4" style="text-align: center">
                  No additional buttons, it is time to create them
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
      <v-tab-item style="min-height: 400px">
        <div style="margin-bottom: 15px;">
          <label  class="label" style="display:block;margin-bottom:15px;font-weight: bold;font-size:20px"></label>
          <div style="margin-bottom: 15px;">
            <label  class="label" style="display: block;margin-bottom: 15px;margin-right: 15px;">Logo Image</label>
            <vue-dropzone
                id="upload"
                ref="upload"
                :options="dropzoneOptions"
                :destroyDropzone="false"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="handleRemove"
                @vdropzone-success="handleSuccess"
                @vdropzone-mounted="mountDropzone"
                style="margin-bottom:15px;"
            >
            </vue-dropzone>
          </div>
          <div style="margin-bottom: 15px;">
            <label  class="label" style="display: block;margin-bottom: 15px;margin-right: 15px;">Logo Image</label>
            <vue-dropzone
                id="uploadMini"
                ref="uploadMini"
                :options="dropzoneOptions"
                :destroyDropzone="false"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="handleRemoveMini"
                @vdropzone-success="handleSuccessMini"
                @vdropzone-mounted="mountDropzoneMini"
                style="margin-bottom:15px;"
            >
            </vue-dropzone>
          </div>
          <div style="display: flex;align-items: center;margin-bottom: 15px;">
            <label class="label" style="display: block;margin-bottom: 0;width: 150px;">Link</label>
            <v-text-field outlined dense hide-details v-model="settings.logo.link"></v-text-field>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item style="min-height: 400px;padding-top:15px;">
        <div style="margin-bottom: 15px;">
          <div style="display: flex;align-items: center;margin-bottom: 15px;">
            <label class="label" style="display: block;margin-bottom: 0;margin-right: 15px;">Default domain (constructor):</label>
            <v-text-field outlined dense hide-details v-model="settings.domain" style="max-width: 300px;"></v-text-field>
          </div>
          <div>
            <label  class="label" style="display: block;margin-bottom: 15px;margin-right: 15px;">Logo Image</label>
            <vue-dropzone
                id="uploadLogo"
                ref="uploadLogo"
                :options="dropzoneOptions"
                :destroyDropzone="false"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="handleRemoveLogo"
                @vdropzone-success="handleSuccessLogo"
                @vdropzone-mounted="mountDropzoneLogo"
                style="margin-bottom:15px;"
            >
            </vue-dropzone>
          </div>
          <div>
            <label  class="label" style="display: block;margin-bottom: 15px;margin-right: 15px;">Background Image</label>
            <vue-dropzone
                id="uploadBackground"
                ref="uploadBackground"
                :options="dropzoneOptions"
                :destroyDropzone="false"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="handleRemoveBackground"
                @vdropzone-success="handleSuccessBackground"
                @vdropzone-mounted="mountDropzoneBackground"
                style="margin-bottom:15px;"
            >
            </vue-dropzone>
          </div>
          <div>
            <label class="label" style="display:block;margin-bottom: 15px;font-size: 20px">User payments settings</label>
            <div style="display: flex;align-items: center;">
              <label class="label" style="display: block;margin-bottom: 0;margin-right: 15px;">User payments available</label>
              <v-switch color="#6ab64a" v-model="settings.tariff.default" hide-details style="padding: 0;margin: 0"></v-switch>
            </div>
          </div>
          <div style="margin-bottom: 15px;" v-if="!settings.tariff.default">
            <label  class="label" style="display:block;margin-bottom:15px;font-weight: bold;font-size:20px"></label>
            <Editor
                api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                :init="options"
                v-model="settings.tariff.default_text"
            >
            </Editor>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item style="min-height: 400px;padding-top:15px;">
        <div style="margin-bottom: 15px;">
          <label  class="label" style="display:block;margin-bottom:15px;font-weight: bold;font-size:20px">Register message</label>
          <Editor
              api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
              :init="options"
              v-model="settings.authorisation.register"
          >
          </Editor>
        </div>

        <div style="color: #7f8385; margin-bottom: 15px; font-size: 14px">
          You can use the following variables: <br />
          $link - your verify email link<br />
        </div>
        <div style="margin-bottom: 15px;">
          <label  class="label" style="display:block;margin-bottom:15px;font-weight: bold;font-size:20px">Forget password message</label>
          <Editor
              api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
              :init="options"
              v-model="settings.authorisation.recover"
          >
          </Editor>
        </div>
        <div style="color: #7f8385; margin-bottom: 15px; font-size: 14px">
          You can use the following variables: <br />
          $link - your recovery email link<br />
        </div>
      </v-tab-item>
    </v-tabs>
<div style="display: flex;justify-content: flex-end" v-if="active_tab !== 3 && active_tab!== 2">
  <v-btn outlined color="primary" @click="saveSettings">
    Save
  </v-btn>
</div>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="createHelpModal" max-width="700px" eager>
      <v-card>
        <v-icon
            style="top:10px;right: 10px;position:absolute;"
            class="close__modal"
            @click="createHelpModal = false"
        >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">New help item</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Question</label>
                <v-text-field
                    outlined
                    dense
                    v-model="createHelpItem.question"
                    placeholder="Write your question"
                    no-resize
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Answer</label>
                <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="createHelpItem.answer"
                    placeholder="Write your answer"
                >
                </Editor>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions style="padding: 0 36px 20px 36px;">
          <v-spacer></v-spacer>
          <v-btn
              class="object__submit btn btn_primary"
              color="primary"
              outlined
              small
              @click="createHelpConfirm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="editHelpModal" max-width="700px" eager>
      <v-card v-if="editHelpItem">
        <v-icon
            style="top:10px;right: 10px;position:absolute;"
            class="close__modal"
            @click="editHelpModal = false"
        >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">Edit help item</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Question</label>
                <v-text-field
                    outlined
                    dense
                    v-model="editHelpItem.question"
                    placeholder="Write your question"
                    no-resize
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Answer</label>
                <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="editHelpItem.answer"
                    placeholder="Write your answer"
                >
                </Editor>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions style="padding: 0 36px 20px 36px;">
          <v-spacer></v-spacer>
          <v-btn
              class="object__submit btn btn_primary"
              color="primary"
              outlined
              small
              @click="editHelpConfirm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PopupDelete ref="PopupDeleteHelp" title="Are you sure about delete help item?" @confirm="deleteHelpItemConfirm"/>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="createMenuModal" max-width="700px">
      <v-card>
        <v-icon
            style="top:10px;right: 10px;position:absolute;"
            class="close__modal"
            @click="createMenuModal = false"
        >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">New menu item</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Name</label>
                <v-text-field
                    outlined
                    dense
                    v-model="createMenuItem.name"
                    placeholder="Write your menu name"
                    no-resize
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Link</label>
                <v-text-field
                    outlined
                    dense
                    v-model="createMenuItem.link"
                    placeholder="Write your menu link"
                    no-resize
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Icon</label>
                <v-autocomplete
                    outlined
                    :items="menuIcons"
                    dense
                    v-model="createSelectedIcon"
                    no-resize
                    return-object
                    item-id="id"
                    item-value="name"
                    @change="changeIcon"
                >
                  <template slot="selection" slot-scope="{ item }">
                    <v-icon>mdi-{{ item.name }}</v-icon>
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <v-icon>mdi-{{ item.name }}</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions style="padding: 0 36px 20px 36px;">
          <v-spacer></v-spacer>
          <v-btn
              class="object__submit btn btn_primary"
              color="primary"
              outlined
              small
              @click="createMenuConfirm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="editMenuModal" max-width="700px">
      <v-card v-if="editMenuItem">
        <v-icon
            style="top:10px;right: 10px;position:absolute;"
            class="close__modal"
            @click="editMenuModal = false"
        >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">Edit menu item</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Name</label>
                <v-text-field
                    outlined
                    dense
                    v-model="editMenuItem.name"
                    placeholder="Write your menu name"
                    no-resize
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Link</label>
                <v-text-field
                    outlined
                    dense
                    v-model="editMenuItem.link"
                    placeholder="Write your menu link"
                    no-resize
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label" style="display: block;margin-bottom:15px;">Icon</label>
                <v-autocomplete
                    outlined
                    :items="menuIcons"
                    dense
                    v-model="editMenuIcon"
                    no-resize
                    return-object
                    item-id="id"
                    item-value="name"
                    @change="changeIconEdit"
                >
                  <template slot="selection" slot-scope="{ item }">
                    <v-icon>mdi-{{ item.name }}</v-icon>
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <v-icon>mdi-{{ item.name }}</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions style="padding: 0 36px 20px 36px;">
          <v-spacer></v-spacer>
          <v-btn
              class="object__submit btn btn_primary"
              color="primary"
              outlined
              small
              @click="editMenuConfirm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PopupDelete ref="PopupDeleteMenu" title="Are you sure about delete menu item?" @confirm="deleteMenuItemConfirm"/>
    <PopupSuccess
        ref="PopupSuccess"
        title="Success"
        :content="successMessage"
    />
  </div>
</template>

<script>
  import AgencyService from "../services/agency.service"
  import titleMixin from "../mixins/titleMixin"
  import vueDropzone from "vue2-dropzone"
  import "vue2-dropzone/dist/vue2Dropzone.min.css"
  import PopupDelete from "@/components/Popup/PopupDelete";
  import menuIcons from "@/assets/meta.json"
  import menu from "../store/menu"
  import {mapState} from 'vuex'
  import PopupSuccess from "@/components/Popup/PopupSuccess"
  export default {
    mixins:[titleMixin],
    components:{
      vueDropzone,
      PopupDelete,
      PopupSuccess
    },
    computed:{
      ...mapState(['settings']),
    },
    title(){
      return "Partner Settings"
    },
    data(){
      return{
        domain:'guest.eco',
        successMessage:"",
        deleteMenuItemID:null,
        editMenuIcon:null,
        editMenuItem:null,
        editMenuModal:null,
        createSelectedIcon:null,
        createMenuItem:{
          id: null,
          name: "",
          icon: "",
          link:""
        },
        createMenuModal:false,
        deleteHelpItemID:null,
        editHelpModal:false,
        settingsID:null,
        editHelpItem:null,
        createHelpItem:{
          id:null,
          question:"",
          answer:""
        },
        active_tab:0,
        menuIcons:[],
        createHelpModal:false,
        dropzoneOptions: {
          addRemoveLinks: true,
          url: () => {
            return process.env.VUE_APP_API_URL + "upload-file-for-user";
          },
        },
        helpHeaders:[
          { text: 'Question', value: "question", align: 'left'},
          { text: 'Answer', value: "answer", align: 'left' },
          {
            text:'Options',align:'left'
          }
        ],
        menuHeaders:[
          { text: 'Icon', value: "icon", align: 'left'},
          { text: 'Name', value: "name", align: 'left'},
          { text: 'Link', value: "link", align: 'left'},
          {
            text:'Options',align:'left'
          }
        ],
        users:[],
        selectedUser:null,
        options: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

          mobile: {
            images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
            images_file_types: 'jpg,svg,webp,gif,png',
            images_upload_handler: async  (blobInfo, success, failure) => {
              let formData = new FormData();
              let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
              formData.append("lending_settings_id", this.$store.state.landing.settings_id);
              formData.append("image", _file);
              formData.append("file", _file);
              const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
              success(data);
            },
            plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
          },
          menu: {
            tc: {
              title: "Comments",
              items: "addcomment showcomments deleteallconversations",
            },
          },
          menubar: "file edit view insert format tools table tc help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
          autosave_ask_before_unload: true,min_height:200,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          height: 200,
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          content_style: ".mymention{ color: gray; }",
          contextmenu: "link image imagetools table configurepermanentpen",
          skin: "oxide",

          setup:function(editor){
            editor.ui.registry.addButton('custom_button', {
              text: 'Add Button',
              onAction: function() {
                // Open a Dialog
                editor.windowManager.open({
                  title: 'Add custom button',
                  body: {
                    type: 'panel',
                    items: [{
                      type: 'input',
                      name: 'button_label',
                      label: 'Button label',
                      flex: true
                    },{
                      type: 'input',
                      name: 'button_href',
                      label: 'Button href',
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_target',
                      label: 'Target',
                      items: [
                        {text: 'None', value: ''},
                        {text: 'New window', value: '_blank'},
                        {text: 'Self', value: '_self'},
                        {text: 'Parent', value: '_parent'}
                      ],
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_style',
                      label: 'Style',
                      items: [
                        {text: 'Success', value: 'success'},
                        {text: 'Info', value: 'info'},
                        {text: 'Warning', value: 'warning'},
                        {text: 'Error', value: 'error'}
                      ],
                      flex: true
                    }]
                  },
                  onSubmit: function (api) {

                    var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                    // insert markup
                    editor.insertContent(html);

                    // close the dialog
                    api.close();
                  },
                  buttons: [
                    {
                      text: 'Close',
                      type: 'cancel',
                      onclick: 'close'
                    },
                    {
                      text: 'Insert',
                      type: 'submit',
                      primary: true,
                      enabled: false
                    }
                  ]
                });
              }
            });
          },
          content_css: ["/custom_tinymce.css"],
        },
        menuItems:[],
        helpItems:[
          {
            id:0,
            question:"How to order stickers or cards for rooms and reception?",
            answer:`Send us an <a href="mailto:guest@tourist.com">email</a> and tell us your wishes.`
          },
          {
            id:1,
            question:"Do you have a designer who will prepare the layout of stickers and cards?",
            answer:`Yes, we provide such services, please contact us. contact us by <a href="mailto:guest@tourist.com">email</a>`
          },
          {
            id:2,
            question:"Can you help me customize the appearance of the app?",
            answer:`Yes, send an <a href="mailto:guest@tourist.com">email</a> request`
          },
          {
            id:3,
            question:"Do you provide advice on how best to make an app for a hotel, camping, etc.?",
            answer:`Yes,  <a href="mailto:guest@tourist.com">email</a> us your request and we will help`
          },
        ],
        logoSettings:{
          position:"top",
          link:"",
          image:null
        },
        contact:`
                <h1>Contact</h1>
                <h4 style="font-weight: normal;">If you have any questions or suggestions. then please write to us at <a href="mailto:guest@tourist.com">guest@tourist.com</a></h4>`,
          legal:`
      <h1>Privacy & Legal</h1>
      <h4 style="font-weight: normal;">We will add information to this page soon.</h4>`
      }
    },
    name: "SettingsPartners",
    methods:{
      handleSuccess(file){
        this.settings.logo.image = file.xhr.response
      },
      handleRemove(){
        this.settings.logo.image = null
      },
      mountDropzone(){
        if(this.settings.logo.image){
          let mockFile = {
            accepted: true,
            kind: "image",
          };
          this.$refs.upload.manuallyAddFile(mockFile, this.settings.logo.image);
          this.$refs.upload.dropzone.emit("thumbnail", mockFile, this.settings.logo.image);
          this.$refs.upload.dropzone.emit("complete", mockFile);
        }
      },
      handleSuccessMini(file){
        this.settings.logo.mini = file.xhr.response
      },
      handleRemoveMini(){
        this.settings.logo.mini = null
      },
      mountDropzoneMini(){
        if(this.settings.logo.mini){
          let mockFile = {
            accepted: true,
            kind: "image",
          };
          this.$refs.uploadMini.manuallyAddFile(mockFile, this.settings.logo.mini);
          this.$refs.uploadMini.dropzone.emit("thumbnail", mockFile, this.settings.logo.mini);
          this.$refs.uploadMini.dropzone.emit("complete", mockFile);
        }
      },
      handleRemoveLogo(){
        this.settings.authorisation.logo = null
      },
      handleSuccessLogo(file){
        this.settings.authorisation.logo = file.xhr.response
      },
      mountDropzoneLogo(){
        if(this.settings.authorisation.logo){
          let mockFile = {
            accepted: true,
            kind: "image",
          };
          this.$refs.uploadLogo.manuallyAddFile(mockFile, this.settings.authorisation.logo);
          this.$refs.uploadLogo.dropzone.emit("thumbnail", mockFile, this.settings.authorisation.logo);
          this.$refs.uploadLogo.dropzone.emit("complete", mockFile);
        }
      },
      handleRemoveBackground(){
        this.settings.authorisation.background = null
      },
      handleSuccessBackground(file){
        this.settings.authorisation.background = file.xhr.response
      },
      mountDropzoneBackground(){
        if(this.settings.authorisation.background){
          let mockFile = {
            accepted: true,
            kind: "image",
          };
          this.$refs.uploadBackground.manuallyAddFile(mockFile, this.settings.authorisation.background);
          this.$refs.uploadBackground.dropzone.emit("thumbnail", mockFile, this.settings.authorisation.background);
          this.$refs.uploadBackground.dropzone.emit("complete", mockFile);
        }
      },
      async saveSettings(){
        // const payload = {
        //   id:this.settingsID,
        //   user_id:this.$store.state.profile.id,
        //   contact:this.contact,
        //   legal:this.legal,
        //   menu:this.menuItems,
        //   help:this.helpItems,
        //   logo:this.logoSettings
        // };
        const data = await AgencyService.putAgencySettings(this.settings);
        this.successMessage = 'New settings saved!!';
        this.$refs.PopupSuccess.open();
      },
      openDeleteMenuItem(item){
        this.deleteMenuItemID = item.id;
        this.$refs.PopupDeleteMenu.open();
      },
      deleteMenuItemConfirm(){
        const candidate = this.settings.menu.find(x => x.id === this.deleteMenuItemID);
        if(candidate){
          const candidateIndex = this.settings.menu.indexOf(candidate);
          if(candidateIndex>=0){
            this.settings.menu.splice(candidateIndex,1);
          }
        }
        this.saveSettings();
      },
      openEditModal(item){
        this.editMenuItem = JSON.parse(JSON.stringify(item));
        this.editMenuIcon = this.menuIcons.find(x => 'mdi-'+x.name === item.icon);
        this.editMenuModal = true;
      },
      editMenuConfirm(){
        const candidate = this.settings.menu.find(x => x.id === this.editMenuItem.id);
        if(candidate){
          const candidateIndex = this.settings.menu.indexOf(candidate);
          if(candidateIndex>=0){
            this.settings.menu.splice(candidateIndex,1,JSON.parse(JSON.stringify(this.editMenuItem)));
          }
        }
        this.saveSettings();
        this.editMenuModal = false;
      },
      openLink(link){
        window.open(link,'_blank');
      },
      changeIcon(val){
        this.createMenuItem.icon = `mdi-${val.name}`;
      },
      changeIconEdit(val){
        this.editMenuItem.icon = `mdi-${val.name}`;
      },
      openCreateMenu(){
        this.createSelectedIcon = null;
        this.createMenuItem = {
          id: null,
          name: "",
          icon: "",
          link:""
        };
        this.createMenuModal = true;
      },
      createMenuConfirm(){
        this.createMenuItem.id = 8+this.settings.menu.length;
        this.settings.menu.push(this.createMenuItem);
        this.createMenuModal = false;
        this.saveSettings();
      },
      deleteHelpItemConfirm(){
        const candidate = this.settings.help.find(x => x.id === this.deleteHelpItemID);
        if(candidate){
          const candidateIndex = this.settings.help.indexOf(candidate);
          if(candidateIndex>=0){
            this.settings.help.splice(candidateIndex,1);
          }
        }
        this.saveSettings();
      },
      openDeleteHelp(item){
        this.deleteHelpItemID = item.id;
        this.$refs.PopupDeleteHelp.open();
      },
      editHelpConfirm(){
        const candidate = this.settings.help.find(x => x.id === this.editHelpItem.id);
        if(candidate){
          const candidateIndex = this.settings.help.indexOf(candidate);
          if(candidateIndex>=0){
            this.settings.help.splice(candidateIndex,1,JSON.parse(JSON.stringify(this.editHelpItem)));
          }
        }
        this.editHelpModal = false;
        this.saveSettings();
      },
      openEditHelp(item){
        this.editHelpItem = JSON.parse(JSON.stringify(item));
        this.editHelpModal = true;
      },
      openCreateHelp(){
        this.createHelpItem = {
          id:null,
          question:"",
          answer:""
        };
        this.createHelpModal = true;
      },
      createHelpConfirm(){
        this.createHelpItem.id = this.settings.help.length;
        this.settings.help.push(this.createHelpItem);
        this.createHelpModal = false;
        this.saveSettings();
      },
      async customEvent(_file, xhr, formData) {
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
        );
        formData.append(
          "user_id",
          this.$store.state.profile.id
        );
        formData.append('image',_file);
      },
    },
    async mounted(){
      this.users = await AgencyService.getUsers();
      if(this.$route.query.user_id){
        this.selectedUser = this.users.find(x => x.id === +this.$route.query.user_id);
      }else{
        this.selectedUser = this.users.length?this.users[0]:null;
      }
      this.menuIcons = menuIcons;
    }
  }
</script>

<style lang="scss">
  .dz-size {
    display: none !important;
  }
  .dz-filename {
    display: none !important;
  }
  .dz-preview {
    & .dz-image {
      & img {
        width: 150px;
        height: 150px;
        object-fit: contain;
      }
    }
  }
</style>
